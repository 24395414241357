nav:not(.paginate, .breadcrumb-wrapper){
  min-height:75px;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.15);
  background: linear-gradient(#2c4770 20%, #051a38 100%);
  background: -webkit-linear-gradient(#2c4770 20%, #051a38 100%);
  background: -moz-linear-gradient(#2c4770 20%, #051a38 100%);
  background:#2c4770;
}


.nav-dropdown{
  display:none;
}

.nav-item{
  display: flex;
  align-items: center;
}

.navbar-nav .nav-link{
  border-bottom: 2px solid transparent;
  padding: .5rem 1rem .2rem;
}

.navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .75rem;
    padding-left: .75rem;
}

/*.dropdown:hover>.dropdown-menu {
  display: block;
  transition: 1.5s;
}*/

.move-nav-menu{
  /*position: relative;*/
  border-radius: 0px;
  top:2em;
}

.dropdown-item:hover{
  background-color: #051a38;
  color:#fff;
  text-decoration: none;
  transition: .75s;
}

.navbar-nav .nav-item::after{
  content:'';
  position: absolute;
}

.dropdown-menu{
  padding-top:0;
}
.dropdown-item.disabled{
  color:#000;
  background-color: #eaeaea;
  font-weight: 700;
}

/*.navbar-nav .nav-item:before{
  width:0%;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 2px solid #fff;
  transition: .5s;
}*/



.navbar-nav .nav-item:hover:before{
  width: 100%;
}

/*.navbar-nav .nav-link:hover{

  transition: .25s;
}*/

.navbar-nav .nav-link:hover{
  border-bottom: 2px solid #fff;
  content:'';
  transition: .5s;
  background-color: rgba(255, 255, 255, 0.1);

}

.breadcrumb{
  background-color:#fff;
}

.dropdown-menu{
  left:-1px;
  transition: .5s;
  opacity:.95;
}

/* Progress bar */
/*#progress-bar {
  --scrollAmount: 0%;

  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  width: var(--scrollAmount);

  height: 25px;
  position: fixed;
  top: 0;
  z-index:1000;
}*/
